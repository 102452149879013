import React, { useState, useRef, useEffect } from 'react';
import './ProcedureSection.css';

// 画像をインポート
import ProcedureImage01 from '../assets/images/admission/procedure-image-01.png';
import ProcedureImage02 from '../assets/images/admission/procedure-image-02.png';
import ProcedureImage03 from '../assets/images/admission/procedure-image-03.png';
import ProcedureImage04 from '../assets/images/admission/procedure-image-04.png';
import ProcedureImage05 from '../assets/images/admission/procedure-image-05.png';
import ProcedureImage06 from '../assets/images/admission/procedure-image-06.png';

const ProcedureSection = () => {
const procedures = [
    { id: 1, title: '入学申し込みフォームから送信', description: ['下記の入学申し込みフォームより申請を行います。'], image: ProcedureImage01 },
    { id: 2, title: '費用の支払い', description: ['お支払いが確認できましたら、次のステップに進みます。', '補助金対象の方は、事前に確認させていただきます。', 'ローン等のサポートもありますので、ご相談ください。'], image: ProcedureImage02 },
    { id: 3, title: '各種ツール設定のご案内', description: ['学習に必要なツールやアカウント設定についてご案内します。', '学習管理システムやメタバース空間へ入っていただきます。'], image: ProcedureImage03 },
    { id: 4, title: 'ヒアリング&目標設定 面談', description: ['担当者やキャリアコンサルタントとの面談を通じて目標設定を行います。', '学習目標やスケジュール設定も同時に行います。'], image: ProcedureImage04 },
    { id: 5, title: 'メタバース留学のご案内', description: ['留学プログラムの詳細をご案内します。', '入学から3ヶ月間、無料で使用できます。', 'オリエンテーションや専属のバディと語学学習ができます。'], image: ProcedureImage05 },
    { id: 6, title: '授業スタート', description: ['いよいよ授業が開始されます！', 'ご自身のペースで、確実に一歩ずつ、進めていきましょう。', '将来をしっかりと見据えて、サポートさせていただきます。'], image: ProcedureImage06 },
];

    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const scrollTimeout = useRef(null);

    const handleScroll = () => {
    if (containerRef.current) {
        const scrollLeft = containerRef.current.scrollLeft;
        const itemWidth = containerRef.current.querySelector('.procedure-item').offsetWidth + 20; // アイテム間のギャップを考慮
        const index = Math.round(scrollLeft / itemWidth);
        setCurrentIndex(index);
    }
    };

    useEffect(() => {
    const container = containerRef.current;
        if (container) {
            const onScroll = () => {
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
                scrollTimeout.current = setTimeout(() => {
                    handleScroll();
                }, 100);
            };
            container.addEventListener('scroll', onScroll);
            return () => {
                container.removeEventListener('scroll', onScroll);
            };
        }
    }, []);

    const handleNext = () => {
        if (currentIndex < procedures.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            scrollToIndex(newIndex);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            scrollToIndex(newIndex);
        }
    };

    const scrollToIndex = (index) => {
        if (containerRef.current) {
            const itemWidth = containerRef.current.querySelector('.procedure-item').offsetWidth + 20; // アイテム間のギャップを考慮
            const scrollPosition = itemWidth * index;
            containerRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
    <div className='procedure-section'>
        <h2 className='procedure-title'>Procedure</h2>
        <p className='procedure-description'>入学から授業スタートまでの手順です。</p>

        <div className='procedure-container'>
            {/* 左矢印 */}
            {currentIndex > 0 && (
                <div className='arrow left-arrow' onClick={handlePrev}>
                <i className='fa-solid fa-chevron-left'></i>
                </div>
            )}

            <div className='procedure-slider' ref={containerRef}>
                {procedures.map((procedure) => (
                <div key={procedure.id} className='procedure-item'>
                    <img src={procedure.image} alt={procedure.title} className='procedure-image' />
                    <h3><i className='fa-solid fa-circle'></i>{procedure.title}</h3>
                    <p>
                        {procedure.description.map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
                ))}
            </div>

            {/* 右矢印 */}
            {currentIndex < procedures.length - 1 && (
                <div className='arrow right-arrow' onClick={handleNext}>
                    <i className='fa-solid fa-chevron-right'></i>
                </div>
            )}
        </div>

        {/* ドットナビゲーション */}
        <div className='dots-container'>
        {procedures.map((_, idx) => (
            <span
                key={idx}
                className={`dot ${currentIndex === idx ? 'active' : ''}`}
                onClick={() => {
                    setCurrentIndex(idx);
                    scrollToIndex(idx);
            }}
            ></span>
        ))}
        </div>
    </div>
    );
};

export default ProcedureSection;