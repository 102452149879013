import React from 'react';
import './CampaignSection.css';
// import { FaFilePdf } from 'react-icons/fa';

const CampaignSection = () => {
return (
    <div className="campaign-section">
        <h3 className="campaign-title">
    初年度入学キャンペーン
        </h3>
        <p className="campaign-description">
        <i className="fa-solid fa-circle"></i>2025年4月、学校がスタートします。<br />
        <i className="fa-solid fa-circle"></i>2025年3月末までに入学していただいた方は、<strong>コース費用が半額</strong>（定員40名）になります。さらに、補助金も適用できます。<br />
        <i className="fa-solid fa-circle"></i>2025年5月以降、および定員に達した場合は、通常のコース費用が適用されます。<br />
        <i className="fa-solid fa-circle"></i>入学は、1年中いつでも可能です。
        </p>
        {/* <div className="campaign-action">
            <a href="/path/to/campaign-details.pdf" target="_blank" rel="noopener noreferrer" className="details-button">
                詳しく見る [PDF] <FaFilePdf className="pdf-icon" />
            </a>
        </div> */}
    </div>
);
};

export default CampaignSection;