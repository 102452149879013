import React from 'react';
import './WorkStyles.css'; // CSS file
import topImageWork from '../assets/images/workstyles/workstyle-image-top.jpeg'; // Adjust the image path accordingly
import FindWorkSection from './FindWorkSection'; // Create these components
import StartupSupportSection from './StartupSupportSection';
import FreelanceSection from './FreelanceSection';
import ContactButtonSection from './ContactButtonSection';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const WorkStyles = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className="work-styles-page">
      <div className="page-title">
        <h1>働き方について</h1>
        <p>Work Styles</p>
      </div>
      <div className="learning-image-container">
        <img src={topImageWork} alt="働き方" className="top-image" />
      </div>
      <section id="find-work-section">
        <FindWorkSection />   {/* Section for "Find Work" */}
      </section>
      <section id="startup-support-section">
        <StartupSupportSection />    {/* Section for "Startup" */}
      </section>
      <section id="freelance-section">
        <FreelanceSection />  {/* Section for "Freelance" */}
      </section>

      <ContactButtonSection />

    </div>
  );
};

export default WorkStyles;